/* import __COLOCATED_TEMPLATE__ from './attribute-picker.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// @ts-nocheck
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

const ATTRIBUTE_LIST_SECTIONS = {
  userAttributes: { name: 'People attributes', icon: 'recipient-data' },
  appAttributes: { name: 'App/author attributes', icon: 'app' },
  companyAttributes: { name: 'Company attributes', icon: 'company' },
  eventAttributes: { name: 'Event attributes', icon: 'event' },
  eventMetadataAttributes: { name: 'Event metadata', icon: 'custom-data' },
};

export default class AttributePicker extends Component<{
  insert(type: string): null;
  resolver?: any;
  filter?: 'action' | 'attribute' | null;
}> {
  @service declare appService: any;
  @service attributeService;
  @service store;
  @service intl;

  @tracked searchQuery = '';

  @action
  updateSearchQuery(query) {
    this.searchQuery = query;
  }

  get filteredAttributeSections() {
    let query = this.searchQuery.toLowerCase();

    return this.filteredAttributesForFinTask
      .map((section) => {
        let filteredList = section.list.filter((attr) => attr.name.toLowerCase().includes(query));
        let filteredRulesets = section.rulesets
          ? section.rulesets
              .map((ruleset) => ({
                ...ruleset,
                list: ruleset.list.filter((attr) => attr.name.toLowerCase().includes(query)),
              }))
              .filter((ruleset) => ruleset.list.length > 0)
          : null;

        return {
          ...section,
          list: filteredList,
          rulesets: filteredRulesets,
          icon: section.icon,
        };
      })
      .filter(
        (section) => section.list.length > 0 || (section.rulesets && section.rulesets.length > 0),
      );
  }

  get filteredAttributesForFinTask() {
    // if we have a filter passed in, use it
    let attributeFilterSections;
    if (this.args.filter === 'action') {
      attributeFilterSections = this.attributeSections.filter(
        (section) => section.name === 'Actions',
      );
    } else if (this.args.filter === 'attribute') {
      attributeFilterSections = this.attributeSections.filter(
        (section) => section.name !== 'Actions',
      );
    } else if (this.args.filter === 'temporary-attributes') {
      attributeFilterSections = this.attributeSections.filter(
        (section) => section.name === 'Temporary attributes',
      );
    } else {
      // if we don't have a filter passed in, use all sections
      attributeFilterSections = this.attributeSections;
    }
    return attributeFilterSections;
  }

  get searchPlaceholderText() {
    if (this.args.filter === 'action') {
      return this.intl.t('inbox.conversation-details-sidebar.search-actions');
    } else {
      return this.intl.t('inbox.conversation-details-sidebar.search-attributes');
    }
  }

  get allowedAttributes() {
    if (this.args.resolver.hideAppAllowedAttributes) {
      return [];
    }
    return this.appService.app?.allowedAttributes || [];
  }

  get allAttributes() {
    return [
      ...this.allowedAttributes.toArray(),
      ...(this.args.resolver.manualAppAttributes || []),
      ...(this.args.resolver.customObjectAttributes || []),
    ];
  }

  get templatableAttributes() {
    return this.allAttributes.filter((attribute) => !!attribute.templatable_identifier);
  }

  get attributeSections() {
    let sectionList = [];

    if (!isEmpty(this.args.resolver.localAttributes)) {
      this.args.resolver.localAttributes.forEach((attributeGroup) => {
        sectionList.addObject({
          name: attributeGroup.heading,
          list: attributeGroup.attributes,
          icon: 'attribute-titles',
        });
      });
    }

    if (!isEmpty(this.args.resolver.customActions)) {
      this.args.resolver.customActions.forEach((attributeGroup) => {
        sectionList.addObject({
          name: attributeGroup.heading,
          list: attributeGroup.attributes,
          icon: 'webhook',
        });
      });
    }

    for (let [key, data] of Object.entries(ATTRIBUTE_LIST_SECTIONS)) {
      let list = this[key];

      if (list.length) {
        if (list.any((item) => item.ruleset)) {
          let rulesets = this.rulesetSubheadings(list);
          sectionList.push({
            name: data.name,
            rulesets,
            list,
            icon: data.icon,
          });
        } else {
          sectionList.push({
            name: data.name,
            list,
            icon: data.icon,
          });
        }
      }
    }

    if (
      this.args.resolver.includeCustomObjectAttributes ||
      this.args.resolver.includeCustomActionAttributes
    ) {
      let customConversationAttributes;
      let ticketAttributes;
      let ticketTypeAttributes;

      if (this.args.resolver.includeCustomObjectAttributes) {
        if (this.args.resolver.includeFileAttributes) {
          customConversationAttributes =
            this.attributeService.nonArchivedConversationCustomAttributesWithFileS3Key;
        } else {
          customConversationAttributes =
            this.attributeService.nonArchivedConversationCustomAttributesWithoutFile;
        }
      }

      if (this.args.resolver.includeCustomActionAttributes) {
        customConversationAttributes = this.attributeService.conversationAttributesForCustomAction;
        ticketAttributes = this.attributeService.ticketAttributesForCustomAction;
        ticketTypeAttributes = this.attributeService.ticketAttributesByType;
      }

      if (customConversationAttributes) {
        sectionList.addObject({
          name: 'Conversation attributes',
          list: customConversationAttributes,
          icon: 'messenger-messages-filled',
        });
      }

      if (ticketAttributes) {
        sectionList.addObject({
          name: 'Ticket attributes',
          list: ticketAttributes,
          icon: 'ticket',
        });
      }

      if (this.appService.app.canUseFeature('ticket-type-attributes-in-cas')) {
        if (ticketTypeAttributes) {
          ticketTypeAttributes.forEach((ticketTypeAttribute) => {
            let heading = `${ticketTypeAttribute.category} - ${ticketTypeAttribute.heading}`;
            let trimmedName;

            if (heading.length > 30) {
              trimmedName = `${heading.slice(0, 30)}...`;
            }

            sectionList.addObject({
              name: heading,
              list: ticketTypeAttribute.attributes,
              trimmedName,
            });
          });
        }
      }

      if (this.args.resolver.webhookReceivedAttributes?.length) {
        sectionList.addObject({
          name: 'Webhook payload attributes',
          list: this.args.resolver.webhookReceivedAttributes,
        });
      }

      sectionList.addObjects(this.customObjectAttributeListSections);
    }

    return this.filterUnallowedAttributesAndRemoveEmptySections(sectionList);
  }

  private filterUnallowedAttributesAndRemoveEmptySections(sectionList: any[]) {
    if (!this.args.resolver?.attributeAllowlist?.length) {
      return sectionList;
    }

    let allowedAttributes = this.args.resolver.attributeAllowlist
      .map((group) => group.attributes)
      .flat();

    return sectionList.reduce((accumulator, section) => {
      // We create a copy of the section to make sure we don't mutate the original
      let filteredSection = Object.assign({}, section);

      // If the resolver defines attributes, we only show those that are defined or belongs to the
      // event metadata
      filteredSection.list = filteredSection.list.filter(
        (item) =>
          -1 !==
          allowedAttributes.findIndex(
            (allAtt) => allAtt.templatable_identifier === item.templatable_identifier,
          ),
      );

      return filteredSection.list.length ? [...accumulator, filteredSection] : accumulator;
    }, []);
  }

  get userAttributes() {
    return this.templatableAttributes.filter((attr) => attr.isUser && !attr.isUserEvent);
  }

  get appAttributes() {
    return this.allAttributes.filterBy('isApp');
  }

  get companyAttributes() {
    return this.templatableAttributes.filterBy('isCompany');
  }

  get eventAttributes() {
    let eventAttributes = [];
    this.allAttributes.filterBy('isUserEvent').forEach((attribute) => {
      let itemData = attribute.toJSON();
      eventAttributes.push(this.duplicateEventAttribute(itemData, 'count'));
      eventAttributes.push(this.duplicateEventAttribute(itemData, 'first'));
      eventAttributes.push(this.duplicateEventAttribute(itemData, 'last'));
    });

    return eventAttributes;
  }

  get eventMetadataAttributes() {
    let userEventMetadataAttributes = this.args.resolver.attributes.filterBy('isUserEventMetadata');
    return [
      ...userEventMetadataAttributes.filter((attribute) =>
        this.selectedEventMetadataIdentifiers.includes(attribute.identifier),
      ),
      ...(this.args.resolver.transientAttributes || []),
    ];
  }

  get selectedEventMetadataIdentifiers() {
    let selectedEvents = this.args.resolver.selectedEvents;
    if (!selectedEvents) {
      return [];
    }
    return selectedEvents.reduce((eventIdentifiers, selectedEvent) => {
      let selectedEventMetadata = selectedEvent.metadata || [];
      return eventIdentifiers.concat(selectedEventMetadata.map((metadata) => metadata.identifier));
    }, []);
  }

  get customObjectAttributeListSections() {
    return this.attributeService.customObjectAttributeGroups.map((customObject) => {
      return { name: customObject.heading, list: customObject.attributes };
    });
  }

  @action
  insert(selectedAttribute) {
    this.args.insert(selectedAttribute.templatable_identifier);
  }

  duplicateEventAttribute(itemData, type) {
    let itemToDuplicate = Object.create(itemData);

    itemToDuplicate.templatable_identifier = `${itemToDuplicate.templatable_identifier}.${type}`;
    itemToDuplicate.name = `${itemToDuplicate.name.replace(/_|\-/g, ' ')} ${type}`;
    itemToDuplicate.isUser = true;
    itemToDuplicate.isUserEvent = true;

    return itemToDuplicate;
  }

  rulesetSubheadings(list) {
    let rulesets = list.map((metadata) => metadata.ruleset).uniq();
    return rulesets.map((ruleset) => {
      return {
        rulesetName: ruleset?.node?.title,
        list: this.eventMetadataAttributes.filter((metadata) => metadata.ruleset === ruleset),
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::AttributePicker': typeof AttributePicker;
    'common/attribute-picker': typeof AttributePicker;
  }
}
